<template>
  <el-drawer
    title=""
    :visible.sync="show"
    :direction="'ltr'"
    :modal-append-to-body="false"
    :show-close="false"
    @closed="close"
    :size="'220px'"
  >
    <div style="
        background-color: #fff;
        position: fixed;
        top: 50px;
        left: 0;
        width: 220px;
      ">
      <img
        :src="logo"
        width="60%"
        style="margin-left: 20%"
      />
    </div>
    <div class="nav">
      <div
        class="nav_item"
        :style="{ color: $route.path !== '/' ? 'normal' : '#ee4b37' }"
        @click="jump('首页', '/')"
      >
        首页
      </div>
      <div
        class="nav_item"
        @click="openCard('商户注册')"
      >商户注册</div>
      <div
        class="nav_item"
        :style="{
          color: $route.path !== '/businessHandbook' ? 'normal' : '#ee4b37',
        }"
        @click="jump('商户使用手册', '/businessHandbook')"
      >
        商户使用手册
      </div>
      <div
        class="nav_item"
        :style="{
          color: $route.path !== '/drInstitute' ? 'normal' : '#ee4b37',
        }"
        @click="jump('华米研究院', '/drInstitute')"
      >
        华米研究院
      </div>
      <div
        class="nav_item"
        :style="{
          color: $route.path !== '/introduceTrafficPromotion' ? 'normal' : '#ee4b37',
        }"
        @click="jump('推广引流', '/introduceTrafficPromotion')"
      >
        <span style="font-size: 1.5em; 
      font-weight: bolder;
      color: #ff7831;
      -webkit-text-stroke: 0px #ff7831; 
      text-stroke: 0px #ff7831;font-style: italic;"> 5合1</span>

      </div>
      <div
        class="nav_item"
        :style="{ color: $route.path !== '/contactUs' ? 'normal' : '#ee4b37' }"
        @click="jump('联系我们', '/contactUs')"
      >
        联系我们
      </div>
      <div
        class="nav_item"
        @click="openCard('下载用户APP')"
      >下载用户APP</div>
      <div
        class="nav_item"
        @click="openCard('关注大人来也')"
      >关注大人来也</div>
    </div>

    <van-overlay
      :show="showCard"
      class="img_card"
    >
      <div>
        <transition name="el-zoom-in-center">
          <div
            class="content"
            v-show="showCard"
          >
            <div class="title">{{ cardTitle }}</div>
            <img
              v-if="cardTitle === '商户注册'"
              :src="require('@/assets/business_h5.png')"
              style="width: 100%"
            />
            <img
              v-else-if="cardTitle === '下载用户APP'"
              :src="require('@/assets/header/download_app.png')"
              style="width: 100%"
            />
            <img
              v-else-if="cardTitle === '关注大人来也'"
              :src="require('@/assets/header/sub_dr.jpg')"
              style="width: 100%"
            />
            <div class="text">长按识别或保存二维码</div>
          </div>
        </transition>

        <i
          class="el-icon-circle-close"
          @click="
            () => {
              showCard = false;
            }
          "
        ></i>
      </div>
    </van-overlay>
  </el-drawer>
</template>

<script>
import VanOverlay from "vant/lib/overlay";
import "vant/lib/overlay/style";
export default {
  components: {
    VanOverlay,
  },
  props: {},
  data() {
    return {
      show: true,
      showCard: false,
      cardTitle: "",
      logo: require("../assets/logo.png"),
    };
  },
  watch: {},
  computed: {},
  methods: {
    openCard(name) {
      this.cardTitle = name;
      this.showCard = true;
      // this.close();
    },
    jump(ac, path) {
      if (this.$route.path !== path) {
        // this.active = ac;
        this.$router.push(path);
        this.close();
      }
    },
    close() {
      this.$emit("close");
    },
  },
  created() { },
  mounted() { },
};
</script>
<style lang='less' scoped>
.nav {
  margin-left: 20%;
  margin-bottom: 92px;
  margin-top: 90px;
  .nav_item {
    color: #000;
    font-size: 14px;
    margin-top: 15px;
    padding-bottom: 15px;
  }
}
.img_card {
  > div {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .content {
      width: 80vw;
      max-width: 400px;
      height: fit-content;
      background: #fdfdfd;
      border-radius: 10px;
      padding: 10px;
      .title {
        text-align: center;
        font-size: 18px;
        font-weight: bolder;

        color: #323232;
        margin: 15px 0px;
      }
      .text {
        margin: 10px 0px;
        text-align: center;
        font-size: 16px;
        color: #323232;
      }
    }
    .el-icon-circle-close {
      font-size: 50px;
      color: #fff;
      margin-top: 20px;
    }
  }
}
</style>