<template>
  <div
    :class="headerClass"
    ref="header"
  >
    <div class="pc_header gW">
      <div
        class="logo"
        @click="jump('首页', '/')"
      >
        <!-- <img
          :src="appLogo"
          height="60%"
        /> -->
        <div class="split"></div>
        <img
          :src="logo"
          height="60%"
        />
      </div>
      <div>
        <div class="nav">
          <div
            class="nav_item"
            :class="active == '首页' ? 'nav_item nav_active' : 'nav_item'"
            @click="jump('首页', '/')"
            @mouseenter="handleEnter($event)"
            @mouseleave="handleLeave($event)"
          >
            <div class="content">
              首页
              <div class="content_bottom"></div>
            </div>
          </div>
          <div
            class="nav_item"
            :class="active == '商户注册' ? 'nav_item nav_active' : 'nav_item'"
            @mouseenter="handleEnter('business_registr')"
            @mouseleave="handleLeave('business_registr')"
            @click="openBiz()"
          >
            <div class="content">
              商户注册
              <!-- <span class="icon iconfont">&#xe6b6;</span> -->
              <div class="content_bottom"></div>
            </div>
            <transition name="fade">
              <!-- <div
                v-if="sub_menu.business_registr "
                class="sub_item"
              >
                <div class="sub_item_body">
                  <img
                    :src="require('../assets/header/business_registr.png')"
                    style="width: 250px;height: 250px"
                  />
                  <div style="text-align: center;margin-top: 1rem;margin-bottom: 1rem;font-size: 1rem;color: #111925">添加商户端小助手</div>
                </div>
              </div> -->
            </transition>
          </div>
          <div
            class="nav_item"
            :class="
              active == '商户使用手册' ? 'nav_item nav_active' : 'nav_item'
            "
            @click="jump('商户使用手册', '/businessHandbook')"
          >
            <div class="content">
              商户使用手册
              <div class="content_bottom"></div>
            </div>
            <transition name="fade">
              <div
                v-if="sub_menu.business_help"
                class="sub_item"
              >
                <div class="sub_item_body">商户使用手册</div>
              </div>
            </transition>
          </div>
          <div
            class="nav_item"
            :class="active == '华米研究院' ? 'nav_item nav_active' : 'nav_item'"
            @click="jump('华米研究院', '/drInstitute')"
            @mouseenter="handleEnter('dr_institute')"
            @mouseleave="handleLeave('dr_institute')"
          >

            <div class="content">
              华米研究院<span class="icon iconfont">&#xe6b6;</span>
              <div class="content_bottom"></div>
            </div>
            <transition name="fade">
              <div
                v-if="sub_menu.dr_institute"
                class="sub_item"
              >
                <div class="sub_item_body">
                  <img
                    :src="require('../assets/header/huami_research.png')"
                    style="width: 150px;margin-top: 10px;"
                  />
                  <div style="
                      text-align: right;
                      margin-top: 1rem;
                      margin-bottom: 1rem;
                      font-size: 1.1rem;
                      color: #4a8ee9;
                      line-height: 1rem;
                      display: flex;
                      align-items: center;
                      justify-content: space-around;
                      font-weight: bolder;
                    ">
                    <span style="font-size: 2rem;">·</span>
                    <span
                      class="text_link"
                      @click="openResearch"
                    >研究院注册</span>
                  </div>
                </div>

              </div>
            </transition>

          </div>
          <div
            class="nav_item"
            :class="active == '推广引流' ? 'nav_item nav_active' : 'nav_item'"
            @click="jump('推广引流', '/introduceTrafficPromotion')"
          >
            <div
              class="content"
              style="font-size: 2em; 
      font-weight: bold;
      color: #ff7831;
      -webkit-text-stroke: 1px #FFF; 
      text-stroke: 1px #FFF;font-style: italic;"
            >
              5合1
              <div class="content_bottom"></div>
            </div>
            <transition name="fade">
              <div
                v-if="sub_menu.attract_users"
                class="sub_item"
              >
                <div class="sub_item_body">推广引流</div>
              </div>
            </transition>
          </div>
          <div
            class="nav_item"
            :class="active == '联系我们' ? 'nav_item nav_active' : 'nav_item'"
            @click="jump('联系我们', '/contactUs')"
            @mouseenter="handleEnter('contact_us')"
            @mouseleave="handleLeave('contact_us')"
          >
            <div class="content">
              联系我们
              <div class="content_bottom"></div>
            </div>
            <!-- <transition name="fade">
              <div
                v-if="sub_menu.contact_us"
                class="sub_item"
              >
                <div class="sub_item_body">联系我们</div>
              </div>
            </transition> -->
          </div>
          <div
            class="nav_item"
            :class="
              active == '下载用户APP' ? 'nav_item nav_active' : 'nav_item'
            "
            @mouseenter="handleEnter('download_app')"
            @mouseleave="handleLeave('download_app')"
          >
            <div class="content">
              下载用户APP <span class="icon iconfont">&#xe6b6;</span>
              <div class="content_bottom"></div>
            </div>
            <transition name="fade">
              <div
                v-if="sub_menu.download_app"
                class="sub_item"
              >
                <div class="sub_item_body">
                  <img
                    :src="require('../assets/header/download_app.png')"
                    style="width: 250px; height: 250px"
                  />
                  <div style="
                      text-align: center;
                      margin-top: 1rem;
                      margin-bottom: 1rem;
                      font-size: 1rem;
                      color: #111925;
                    "></div>
                </div>
              </div>
            </transition>
          </div>
          <div
            class="nav_item"
            :class="
              active == '关注大人来也' ? 'nav_item nav_active' : 'nav_item'
            "
            @mouseenter="handleEnter('sub_dr')"
            @mouseleave="handleLeave('sub_dr')"
          >
            <div class="content">
              关注大人来也<span class="icon iconfont">&#xe6b6;</span>
              <div class="content_bottom"></div>
            </div>
            <transition name="fade">
              <div
                v-if="sub_menu.sub_dr"
                class="sub_item"
              >
                <div class="sub_item_body">
                  <img
                    :src="require('../assets/header/sub_dr.jpg')"
                    style="width: 250px; height: 250px"
                  />
                  <div style="
                      text-align: center;
                      margin-top: 1rem;
                      margin-bottom: 1rem;
                      font-size: 1rem;
                      color: #111925;
                    ">
                    关注大人来也公众号
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>

      <div style="width: 131.75px"></div>
    </div>
    <div class="h5_header gW">
      <div class="menu_icon">
        <span
          class="icon iconfont"
          @click="
            () => {
              h5_menu_show = true;
            }
          "
        >&#xe600;</span>
      </div>
      <img
        :src="logo"
        height="60%"
      />
      <div></div>
    </div>

    <h5_menu
      v-if="h5_menu_show"
      @close="
        () => {
          h5_menu_show = false;
        }
      "
    />
  </div>
</template>

<script>
import h5_menu from "./h5_menu.vue";

export default {
  components: {
    h5_menu,
  },
  props: {},
  data() {
    return {
      headerClass: "header",
      logo: require("../assets/logo.png"),

      appLogo: require("../assets/app_logo.png"),
      business_registr: require("../assets/header/business_registr.png"),
      active: "首页",
      sub_menu: {
        business_registr: false,
        business_help: false,
        dr_institute: false,
        contact_us: false,
        download_app: false,
        sub_dr: false,
        attract_users: false,
      },
      h5_menu_show: false,
    };
  },
  watch: {},
  computed: {},
  methods: {
    openBiz() {
      window.open("https://e.darenlaiye.com/www/#/login");
    },
    openResearch() {
      window.open("http://www.huamiresearch.com/");
    },
    jump(ac, path) {
      if (this.$route.path !== path) {
        this.active = ac;
        this.$router.push(path);
      }
    },
    handleEnter(item) {
      this.$set(this.sub_menu, item, true);
    },
    handleLeave(item) {
      this.$set(this.sub_menu, item, false);
    },
    handleScroll() {
      // 当距离顶部60px时执行你想要的操作
      if (window.scrollY >= 60) {
        // 执行你想要的操作，比如显示一个固定在顶部的导航栏等
        this.$set(this, "headerClass", "header with-filter-background");
      } else {
        this.$set(this, "headerClass", "header");
      }
    },
  },
  created() { },
  mounted() {
    // 添加滚动事件监听
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    // 移除滚动事件监听
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
<style lang='less' scoped>
.text_link:hover {
  cursor: pointer;
  text-decoration: underline;
  color: #4a8ee9 !important;
}

/* 特定于桌面的样式 */
@media screen and (min-width: 1280px) {
  .header {
    color: #fff;
    font-size: 1rem;
    position: relative;
    z-index: 1001;
    .pc_header {
      height: 80px;
      background-color: hsla(7, 84%, 57%, 1);
      position: fixed;
      top: 0px;
      left: 0px;
      .nav {
        display: flex;
        .nav_item {
          padding: 0px 1rem;
          height: 80px;
          // line-height: 80px;
          display: flex;
          align-items: center;
          cursor: pointer;
          position: relative;
          .content {
            position: relative;
            line-height: 1.25rem;
            display: flex;
            align-items: center;
            justify-content: center;
            .icon {
              position: relative;
              top: 2px;
              font-size: 1.25rem;
              line-height: 1.25rem;
              transition: transform 0.3s ease-in-out; /* 平滑过渡动画 */
              transform: rotate(0deg); /* 初始旋转角度 */
              display: inline-block;
            }
            .content_bottom {
              height: 5px;
              width: 0px;
              border-radius: 5px;
              background: #fff;
              bottom: -15px;
              position: absolute;

              transition: width 0.4s; /* 平滑过渡动画 */
            }
          }

          .sub_item {
            top: 80px;
            position: absolute;
            padding-top: 15px;
            left: -15px;
            .sub_item_body {
              background: #fff;
              border-radius: 15px;
              padding: 15px;
              box-shadow: 0px 5px 10px 2px rgba(83, 83, 83, 0.2); /* 鼠标悬浮时的阴影 */
            }
          }
        }

        .nav_item:hover {
          .content {
            .icon {
              transform: rotate(180deg); /* 鼠标悬浮时元素旋转180度 */
            }
            .content_bottom {
              width: 24px;
            }
          }
        }

        .nav_active {
          .content {
            .content_bottom {
              width: 24px !important;
            }
          }
        }
      }
      .logo {
        cursor: pointer;
        height: 100%;
        display: flex;
        align-items: center;
        .split {
          margin: 0px 15px;
          height: 55%;
          border: 0px solid #999999;
        }
      }
    }
    .h5_header {
      display: none;
    }
  }
}

/* 特定于移动设备的样式 */
@media screen and (max-width: 1279px) {
  .header {
    position: relative;
    z-index: 1001;
    .h5_header {
      height: 60px;
      background-color: hsla(7, 84%, 57%, 1);
      position: fixed;
      top: 0px;
      left: 0px;

      justify-content: center;
      .menu_icon {
        position: absolute;
        left: 6vw;
      }
    }
    .pc_header {
      display: none;
    }
  }
}
/* 特定于移动设备的样式 */
@media screen and (max-width: 1024px) {
  .h5_header {
    background-color: #fff !important;
  }
}

.with-filter-background {
  .gW {
    background-color: hsla(7, 84%, 57%, 1);
    backdrop-filter: blur(10px); /* 调整这个值来改变模糊程度 */

    --tw-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.08);
    --tw-shadow-colored: 0 4px 10px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
}

.gW {
  padding: 0px 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // min-width: 500px;
  width: calc(100% - 100px);
}

/* 定义淡入淡出效果 */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
/* .fade-leave-active for <=2.1.8 */
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>